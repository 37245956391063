/* Default dot style */




  /* Default dot style */
.slick-dots li button:before {
    width: 8.44px;
    height: 8.44px;
    gap: 0px;
    border-radius: 6.44px;
    color:transparent;
  background: rgba(217, 217, 217, 1); /* Default dot background */
  opacity: 1;
  }
  
  /* Active dot style */
  .slick-dots li.slick-active button:before {
    /* width: 17.71px; */
    width: 20.71px;
    height: 9.44px;
    gap: 0px;
    color:transparent;
    border-radius: 6.44px;
    margin-left:-5px;
    opacity: 1; /* Full opacity for active dot */
    background: rgba(225, 105, 255, 1); /* Active dot color */
  }
  
  .slick-dots li {
    margin: 0;
    /* width: Fixed (66px)px; */
height: Hug (6.44px)px;
/* top: 896.56px; */
/* left: 687px; */
gap: 4.83px;
width: 16px;


}


.slick-dots {
  position: absolute;
  bottom: 170px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}


@media (max-width: 375px) {
  .slick-dots {
    bottom: 56px !important; /* Adjusted position for smaller screens */
  }
}
@media (max-width: 425px) {
  .slick-dots {
    bottom: 10px !important; /* Adjusted position for smaller screens */
  }
}
@media (max-width: 768px) {
  .slick-dots {
    bottom: 45px !important; /* Adjusted position for smaller screens */
  }
}
/* @media (max-width: 1024px) {
  .slick-dots {
    bottom: 30px; /* Adjusted position for smaller screens */
  /* }
} */ 

@media (max-width: 1024px) {
  .slick-dots {
    bottom: 100px; /* Adjusted position for smaller screens */
   }
}










