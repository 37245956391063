*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

:root {
  --primary: #e169ff;
  --white: #ffffff;
  --bg: #f5f5f5;
}

body {
  font-size: 1.6rem;
  background: var(--bg);
}

.container {
  max-width: 124rem;
  padding: 3rem 1rem;
  margin: 0 auto;
}

.heading {
  padding: 1rem 0;
  font-size: 3.5rem;
  text-align: center;
}

.swiper_container {
  position: relative;
  width: 100%;
}

.swiper-slide {
  height: 42rem;
  position: relative;
  /* height: auto; */
}

.swiper-slide img {
  width: 900px;
  height: 30rem;
  border-radius: 30px !important;
  object-fit: fill;
  position: relative;
  left: -6% !important;
  justify-content: center;
}
.swiper-slide img.custom-slide-img {
  border-radius: 30px !important;
}

/* Media query for screens up to 1440px */
@media (max-width: 1440px) {
  .swiper-slide img {
    margin-top: 120px;
    border-radius: 30px !important;
    height: 21rem !important;
    width: 36rem !important;
    margin-left: 36px;
  }
}
@media (max-width: 2560px) {
  .swiper-slide img {
    left: -24% !important;
    top: 60px;
  }
}

/* Media query for screens between 1024px and 1439px */
@media (min-width: 1024px) and (max-width: 1439px) {
  .swiper-slide img {
    width: 30rem !important;
    height: 20rem !important;
    /* left: -35% !important; */
    left: -35% !important;
    bottom: 10%;
    margin-top: 120px;
    /* width: 600px !important;
    height: 30rem !important; */
  }
}

/* Media query for screens between 768px and 1000px */
@media (min-width: 768px) and (max-width: 1000px) {
  .swiper-slide img {
    border-radius: 30px !important;
    left: -80% !important;
    object-fit: fill;
    position: relative;
    width: 550px !important;

    /* left: -146% !important;  */
  }
}

/* Media query for screens up to 500px */
@media (max-width: 500px) {
  .swiper_container {
    /* height: 47rem; */
    /* height: 40rem; */
    margin-top: -7rem;
  }
  .swiper-slide {
    width: 28rem !important;
    height: 36rem !important;
  }
  .swiper-slide img {
    width: 28rem !important;
    /* height: 36rem !important; */
    border-radius: 30px !important;
    margin-left: -7rem;
  }
}
@media (max-width: 425px) {
  .swiper_container {
    margin-top: -12rem;
    /* height: 47rem; */
  }
  .swiper-slide {
  }
  .swiper-slide img {
    /* width: 62rem !important;
        height: 36rem !important;
        margin-left: -507px; */
    width: 30rem !important;
    height: 20rem !important;
    margin-left: -125px;
  }
}
@media (max-width: 375px) {
  .swiper_container {
    margin-top: -12rem;
    /* height: 38rem; */
  }

  .swiper-slide img {
    width: 42rem !important;
    height: 30rem !important;
    margin-left: -276px;
  }
}
/* Media query for screens up to 425px */
@media (min-width: 500px) and (max-width: 766px) {
  .swiper_container {
    /* height: 47rem; */
    margin-top: -7rem;
  }
  .swiper-slide {
    width: 28rem !important;
    height: 36rem !important;
  }
  .swiper-slide img {
    width: 28rem !important;
    height: 14rem !important;
    margin-left: -13px;
  }
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  bottom: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controler .swiper-button-next {
  left: 54% !important;
  transform: translateX(-58%) !important;
}

/* Media query for screens up to 990px */
@media (max-width: 990px) {
  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

/* Media query for screens up to 450px */
@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

.slider-controler .slider-arrow {
  border-radius: 50%;
  left: 46%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  border-radius: 50%;
  left: 46%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 35, 0.08));
  /* background: rgba(255, 255, 255, 0.24); */
  background: transparent;

  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  position: absolute;
  /* height: 50px;
  width: 50px; */
}

.slider-controler .slider-arrow ion-icon {
  color: #222224;
  position: relative;
}

.slider-controler .slider-arrow::after {
  content: "";
  position: relative;
}

.swiper-pagination {
  position: relative;
  bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
  background: var(--white);
  position: relative;
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  opacity: 1;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
  width: 10.21px;
  height: 6.81px;
  gap: 0px;
  border-radius: 17.02px;
}
